import React from "react"

const LookingForCats = ({ lookingForCat }) => {

  return (
    <section className="content navy-background">
<div className="sixty-spacer"></div>
    {lookingForCat[0].lookingFor.nodes.length
      ? lookingForCat[0].lookingFor.nodes.map(prod => (

   <div className="content-block cta-not-what-wrapper" key={prod.id}>

      <div className="one-flex-container">
                  <div className="twothirds-flex-container sequenFadeIn sequen-visible">
                           <div className="cta-help-title-holder fadeIn visible" dangerouslySetInnerHTML={{ __html: prod.title }}/>
         </div>
      </div>

      <div className="one-flex-container">
         <div className="half-flex-container sequenFadeIn sequen-visible">
               <div className="cta-help-text-holder fadeIn visible" dangerouslySetInnerHTML={{ __html: prod.content }}/>
         </div>
                  <div className="half-flex-container sequenFadeIn sequen-visible">
            <div className="cta-not-what-content-container clearfix fadeIn visible">
               <div className="cta-not-what-label-holder">
                  {prod.whatLookingForOptions.notWhatYourLookingForTitle}
               </div>

               <div className="cta-not-what-link-holder">
                  <nav>
                     <ul>
                     {prod.whatLookingForOptions.notWhatYourLookingForButtonLeftLabel ? (
                       <li>
                        <a href={prod.whatLookingForOptions.notWhatYourLookingForButtonLeftLink}>
                           {prod.whatLookingForOptions.notWhatYourLookingForButtonLeftLabel}
                        </a>
                     </li>
                      ) : null}

                      {prod.whatLookingForOptions.notWhatYourLookingForButtonMiddleLabel ? (
                        <li>
                         <a href={prod.whatLookingForOptions.notWhatYourLookingForButtonMiddleLink}>
                            {prod.whatLookingForOptions.notWhatYourLookingForButtonMiddleLabel}
                         </a>
                      </li>
                       ) : null}

                      {prod.whatLookingForOptions.notWhatYourLookingForButtonRightLabel ? (
                        <li>
                         <a href={prod.whatLookingForOptions.notWhatYourLookingForButtonRightLink}>
                            {prod.whatLookingForOptions.notWhatYourLookingForButtonRightLabel}
                         </a>
                      </li>
                       ) : null}

                                             </ul>
                  </nav>
               </div>
            </div>
         </div>
      </div>

   </div>
      ))
    : null}
    <div className="sixty-spacer"></div>
</section>
  )
}

export default LookingForCats
