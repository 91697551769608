import React, { useState,useEffect } from "react"

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"


function InformationAccordionRepeater({ informationAccordion,informationAccordionTitle }) {
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [showFilter,setShowFilter] = useState(false)

  const updatePosts=(e)=>{
  // insightItems.pop();
  if(url==="/intermediaries/specialist-mortgages/products/specialist-buy-to-let/"){
    setShowFilter(true)
  }
  else(
    setShowFilter(false)
  )
    //if no news items then show both insights
    //console.log("BANNNNNER"+homeBannerGroup.homeBanner.bannerDetails.videoBannerUpload)

  }
  useEffect(() => {
    updatePosts()
  }, []);

  return (
    <section className={`content ${showFilter ? 'very-light-blue-background' : 'navy-background'}`}>
    <div className="eighty-spacer"></div>
      <div className={`content-block clearfix ${showFilter ? 'very-light-blue-background' : 'navy-background'}`}>
        <div className="info-accordion-holder">
        {informationAccordionTitle ?
          <>
        <div className="centered-title-holder">
        <h3>{informationAccordionTitle} </h3>
        </div>
          <div className="eighty-spacer"></div>
          </>
        :null}
          <Accordion collapsible>
          {informationAccordion.length
            ? informationAccordion.map((cat,index) => (
                  <AccordionItem key={index}>
                  <div className={`info-accordion-container ${showFilter ? 'very-light-blue-background' : 'navy-background'}`}>
                    <div className="info-sub-heading-holder">
                      <AccordionButton className="info-sub-heading">
                        {cat.informationSubHeading}
                        <div className="white-mango-status-icon info-plus"></div>
                      </AccordionButton>
                    </div>
                    </div>
                    <AccordionPanel className="info-text-link-container">
                        <div
                          className="info-text"
                          dangerouslySetInnerHTML={{
                            __html: cat.informationText,
                          }}
                        />
                        {cat.informationLink ?
                        <div className="info-link">
                          <a href={cat.informationLink}>Learn More</a>
                        </div>
                        :
                        null}
                    </AccordionPanel>
                  </AccordionItem>
              ))
            : null}
            </Accordion>
        </div>
        <div className="one-hundred-spacer"></div>
      </div>
    </section>
  )
}

export default InformationAccordionRepeater
